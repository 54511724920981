<template>
  <div v-if="partner && partner.asset">
    <img :src="`/img/partner-icons/${partner.asset}`" :width="`${width || '18'}`" alt="partner icon"/>
  </div>
</template>

<script>
export default {
  name: 'basicPartnerIcon',
  props: {
    value: [Number, String],
    width: {
      type: [Number, String],
      default: '18'
    }
  },
  computed: {
    partner () {
      let partner
      if (typeof this.value === 'number') {
        partner = this.$store.getters.partners.filter(partner => partner.partnerId === this.value)[0]
      } else if (typeof this.value === 'string') {
        partner = this.$store.getters.partners.filter(partner => partner.name === this.value)[0]
      }
      return partner
    }
  }
}
</script>

<style scoped>

</style>
